<script setup>
import { Link } from '@inertiajs/vue3';
import { ChevronRightIcon, HomeIcon } from '@heroicons/vue/20/solid'
import Container from './Container.vue';

const props = defineProps({
    pages: Array
})

const icons = {
    home: HomeIcon,
}

console.log('breadcrumbs', props.pages)
</script>

<template>
    <Container class="mb-3">
        <nav class="flex" aria-label="Breadcrumb">
            <ol role="list" class="flex flex-wrap items-center space-x-4">
                <li class="mb-3" v-for="page, key in pages" :key="page.name">
                    <div class="flex items-center">
                        <ChevronRightIcon v-if="key > 0" class="h-5 w-5 mr-4 flex-shrink-0 text-gray-400" aria-hidden="true" />
                        <Link :href="page.url" class="text-sm font-medium text-gray-500 hover:text-gray-700" :aria-current="page.url ? 'page' : undefined">
                            <component 
                                v-if="page.icon"
                                :is="icons[page.icon]"
                                class="h-5 w-5 pb-0.5 flex-shrink-0"
                            />
                            <span v-else>{{ page.title }}</span>
                        </Link>
                    </div>
                </li>
            </ol>
        </nav>
    </Container>
</template>