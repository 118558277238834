<script setup>
import { Link, useForm, usePage } from '@inertiajs/vue3';
import { Bars3Icon, MagnifyingGlassIcon, ShoppingBagIcon, UserIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import { computed } from 'vue';
import { useStore } from 'vuex';
import Container from './Container.vue';
import CatalogMenu from './CatalogMenu.vue';
import ApplicationLogo from '../ApplicationLogo.vue';

const page = usePage();
const store = useStore();
const productsCount = computed(() => store.getters['cart/products_count'])
const rootCategories = computed(() => page.props.rootCategories);

const searchForm = useForm({
    search: null
});

const searchSubmit = (data) => {
    searchForm.get(route('market.catalog'), {
        preserveScroll: false,
    });
};
</script>

<template>
    <!-- Top Line -->
    <Container class="py-2 sm:py-3">
        <nav class="flex justify-between w-100 items-center">
            <div class="text-xs">
                <Link :href="route('market.info.contacts')" class="text-gray-600">Наши магазины</Link>
            </div>
            <div class="flex-1"></div>
            <div class="text-xs">
                <a href="https://seller.ghostshelf.shop" target="_blank" rel="nofollow" class="mr-3 text-sky-900">Стать продавцом</a>
            </div>
        </nav>
    </Container>

    <!-- Main Header -->
    <Container class="mb-6">
        <div class="flex justify-between h-8 sm:h-10 w-100 items-center relative">
            <!-- Logo -->
            <div class="shrink-0 items-center pr-3 hidden sm:flex">
                <Link :href="route('market.home')">
                    <ApplicationLogo
                        class="block h-10 w-auto fill-current text-gray-800"
                    />
                </Link>
            </div>

            <!-- Flyout Menu -->
            <CatalogMenu :rootCategories="rootCategories" />

            <!-- Search -->
            <form @submit.prevent="searchSubmit" class="flex-1 sm:pl-3">
                <div class="relative rounded-md shadow-sm">
                    <input
                        name="search"
                        placeholder="Давайте найдем..."
                        class="py-1.5 px-3 pr-8 sm:py-2 sm:pr-11 text-xs sm:text-sm block w-full border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 rounded-md shadow-sm"
                        v-model="searchForm.search"
                    />
                    <button type="submit" class="border-none text-gray-400 hover:text-gray-500 absolute right-0 top-0 p-1.5 pr-2 sm:p-2 sm:pr-3">
                        <MagnifyingGlassIcon class="h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" />
                    </button>
                </div>
            </form>

            <!-- Account -->
            <Link :href="route('profile.edit', {}, false)" class="p-2 text-gray-400 hover:text-gray-500 ml-2 lg:ml-6">
                <span class="sr-only">Account</span>
                <UserIcon class="h-5 w-5 lg:h-6 lg:w-6" aria-hidden="true" />
            </Link>

            <!-- Cart -->
            <div class="ml-2 flow-root lg:ml-6">
                <Link :href="route('market.cart')" class="group -m-2 flex items-center p-2">
                    <ShoppingBagIcon class="h-5 w-5 lg:h-6 lg:w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                    <span class="ml-1 lg:ml-2 text-xs sm:text-sm font-medium text-gray-700 group-hover:text-gray-800">{{ productsCount }}</span>
                </Link>
            </div>
        </div>
    </Container>
</template>
